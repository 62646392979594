import { modalInfo, modalInfoChooseOption } from "./AppLayout";
import { applyForJobAsync, backendURL } from "./data/state";
import { IpublicJob, JobStatus, statusCrew } from "./types/jobs";
import { IDefaultSnackbarResponse } from "./types/user";

export function formatDurationMS(ms: number, hideSeconds = false) {
  const sec = Math.floor(ms / 1000);
  const hours = Math.floor(sec / 3600);
  let strHours = hours.toString();
  const minutes = Math.floor((sec - hours * 3600) / 60);
  let strMinutes = minutes.toString();
  const seconds = sec - hours * 3600 - minutes * 60;
  let strSec = (sec - hours * 3600 - minutes * 60).toString();

  if (hours < 10) {
    strHours = "0" + hours;
  }
  if (minutes < 10) {
    strMinutes = "0" + minutes;
  }
  if (seconds < 10) {
    strSec = "0" + seconds;
  }
  if (hideSeconds) {
    return strHours + ":" + strMinutes;
  }
  return strHours + ":" + strMinutes + ":" + strSec;
}

function getMonthFull(d: Date, fullName: boolean) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (fullName) {
    return monthNames[d.getUTCMonth()];
  }
  return monthNamesShort[d.getUTCMonth()];
}

//get the earliest starttime from a job to filter purpose(dashboard)
export function getFilterJobDate(job: IpublicJob) {
  if (job.reqCrew.length === 0) return job.date;
  return job.reqCrew.reduce((prev, current) => {
    return prev.startTime < current.startTime ? prev : current;
  }).startTime;
}

export async function doApplyJob(job: {
  id: number;
  reqCrew: {
    confirmed: number;
    amount: number;
    groupName: string;
    id: number;
  }[];
}) {
  if (job.reqCrew.length === 0)
    return {
      error: true,
      snackbar: {
        active: true,
        color: "primary",
        text: "This job is not acceptiong applications",
        timeout: 5000,
      },
    } as IDefaultSnackbarResponse;
  let options: { id: number; value: string }[] = [];
  job.reqCrew.forEach((el) => {
    if (el.confirmed! < el.amount) {
      options.push({ id: el.id, value: el.groupName });
    }
  });
  let applyOnId = job.reqCrew[0].id;
  if (options.length > 1) {
    try {
      const choosen = await showModalChooseOption({
        active: true,
        style: "button",
        text: "On which crew you would like to apply?",
        timeout: 0,
        values: options,
      });
      applyOnId = choosen;
    } catch (error) {
      return {
        error: true,
        snackbar: {
          active: true,
          color: "primary",
          text: "No option choosen",
          timeout: 5000,
        },
      } as IDefaultSnackbarResponse;
    }
  }
  const successful = await applyForJobAsync(job.id, applyOnId);
  if (successful.error) {
    showModal(successful);
  }
  return successful;
}

/**
 *
 * @param job
 * @returns The status of the application for the job
 * Confirmed:
 *   User is confirmed.
 *
 * Filled:
 *   Job is full and user not inside.
 *   Job is not full and user got rejected(Denied).
 *
 * Invited:
 *   Job is not full and user statusCrew is pending.
 *
 * Pending:
 *   Job is not full, user applied but still waiting for admin confirmation.
 *
 * Open:
 *   Job is not full, user is not invited neither applied
 */
export function getApplicationStatus(job: IpublicJob): JobStatus {
  if (job.statusUser === statusCrew.Confirmed) return "confirmed";
  if (job.statusUser === statusCrew.Denied) return "filled"; // Don't say "rejected"
  const hasSpace = job.reqCrew.some((el) => {
    if (el.amount > el.confirmed) return true;
    return false;
  });
  if (!hasSpace) return "filled";
  //checks invited after checking if there is space
  if (job.statusUser === statusCrew.Pending) return "invited";

  //approved or rejected is handled earlier
  if (job.hasApplied) {
    return "pending";
  }
  return "open";
}
//DD/MM/YY hh:mm:ss
export function formatDateTime(format: string, msDate: number): string {
  //D day of Week short (mon..)
  //DD day of month (1..31)
  //DDD day of Week long (monday)
  //MM month number (1..12)
  //MMMM month name short (jan)
  //MMMMM month name (january)
  //YYYY year full
  //YY year short
  //th add th to the day 1st 2nd 3rd 4th...
  //hh hour
  //mm minutes
  //ss Seconds
  //xx am/pm
  //XX AM/PM
  let strDay: string,
    strMonth: string,
    strYear: string,
    strHours: string,
    strMinutes: string,
    strSeconds: string,
    strDayString: string,
    strDayStringLong: string;

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const daysOfWeekAbbreviated = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];

  // const date = new Date();
  // const dayOfWeekIndex = date.getUTCDay();
  // const dayOfWeekName = daysOfWeek[dayOfWeekIndex];
  if (msDate <= 24 * 60 * 60 * 1000) msDate = 0;
  const date = new Date(msDate);
  strDay = date.getUTCDate().toString().padStart(2, "0");
  strDayString = daysOfWeekAbbreviated[date.getUTCDay()];
  strDayStringLong = daysOfWeek[date.getUTCDay()];
  strMonth = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const strMonthFull = getMonthFull(date, true);
  const strMonthShort = getMonthFull(date, false);
  strYear = date.getUTCFullYear().toString();
  let hours = date.getUTCHours();
  strMinutes = date.getUTCMinutes().toString().padStart(2, "0");
  strSeconds = date.getUTCSeconds().toString().padStart(2, "0");
  let th: string;
  if (date.getUTCDate() === 1) {
    th = "st";
  } else if (date.getUTCDate() === 2) {
    th = "nd";
  } else if (date.getUTCDate() === 3) {
    th = "rd";
  } else th = "th";
  let ampm = "AM";
  if (format.includes("xx")|| format.includes("XX")) {
    if (hours >= 12) {
      // 0 to 11:59
      hours = hours - 12;
      if (hours === 0) {
        hours = 12;
      }
      ampm = "PM";
    }
  }
  strHours = hours.toString();
  if (msDate === 0) {
    strDay = "00";
    strMonth = "00";
    strYear = "0000";
    strHours = "00";
    strMinutes = "00";
    strSeconds = "00";
  }
  format = format.replace("DDD", strDayStringLong);
  format = format.replace("DD", strDay);
  format = format.replace("D", strDayString);
  format = format.replace("MMMMM", strMonthFull);
  format = format.replace("MMMM", strMonthShort);
  format = format.replace("MM", strMonth);
  format = format.replace("th", th);
  format = format.replace("YYYY", strYear);
  format = format.replace("YY", strYear.substring(2, 4));
  format = format.replace("hh", strHours.padStart(2, "0"));
  format = format.replace("mm", strMinutes);
  format = format.replace("ss", strSeconds);
  format = format.replace("XX", ampm);
  format = format.replace("xx", ampm.toLowerCase());
  return format;
}

export function parseJwt(token: string) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

/**
 *
 * @param msDate
 * @returns The date of the sunday from the week on the param (UTC)
 */
export function getSunday(msDate: number | Date) {
  let currentDate: Date;
  if (typeof msDate === "number") {
    currentDate = new Date(msDate);
  } else {
    currentDate = msDate;
  }

  const currentDayOfWeek = currentDate.getUTCDay();

  const daysUntilSunday = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

  const nextSunday = new Date(currentDate);
  nextSunday.setUTCDate(currentDate.getUTCDate() + daysUntilSunday);

  return nextSunday;
}

export function normalizeMinutesUTC(msDate: number) {
  /* x:00 to x:07 is x:00
x:07 to x:22 is x:15
x:22 to x:37 is x:30
x:37 to x:52 is x:45
and above 52 it becomes x:00 again */
  const date = new Date(msDate);

  const time = date.getUTCMinutes() * 60 + date.getUTCSeconds();
  if (time <= 7 * 60 + 30) {
    date.setUTCMinutes(0);
  } else if (time <= 22 * 60 + 30) {
    date.setUTCMinutes(15);
  } else if (time <= 37 * 60 + 30) {
    date.setUTCMinutes(30);
  } else if (time <= 52 * 60 + 30) {
    date.setUTCMinutes(45);
  } else {
    date.setUTCHours(date.getUTCHours() + 1);
    date.setUTCMinutes(0);
  }
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date.valueOf();
}

export function normalizeMinutes(msDate: number) {
  /* x:00 to x:07 is x:00
x:07 to x:22 is x:15
x:22 to x:37 is x:30
x:37 to x:52 is x:45
and above 52 it becomes x:00 again */
  const date = new Date(msDate);

  const time = date.getMinutes() * 60 + date.getSeconds();
  if (time <= 7 * 60 + 30) {
    date.setMinutes(0);
  } else if (time <= 22 * 60 + 30) {
    date.setMinutes(15);
  } else if (time <= 37 * 60 + 30) {
    date.setMinutes(30);
  } else if (time <= 52 * 60 + 30) {
    date.setMinutes(45);
  } else {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
  }
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.valueOf();
}

export function isValidDate(str: string) {
  const date = new Date(str);
  return !isNaN(date.getTime());
}

export function toLocalISOString(msDate: number) {
  const date = new Date(msDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function urlPicture(idUser: number) {
  return backendURL + "/api/user/getUserPicture/" + idUser;
}

export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string); // Resolve the promise with the Base64 string
    reader.onerror = (error) => reject(error); // Reject the promise if there's an error
  });
}

export function isAppInstalled() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

export function detectOS() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // If neither iOS nor Android, assume other
  return "Other";
}

export function base64ToBlob(base64: string, mimeType = "application/pdf") {
  const byteCharacters = atob(base64);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: mimeType });
}

export function localDownloadFile(
  file: File | Blob | string,
  filename: string,
  type = "application/octet-stream"
) {
  // Create a Blob from the file data
  const blob = new Blob([file], { type });

  // Create an Object URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "ph_download";
  document.body.appendChild(a);
  a.click();

  // Clean up by revoking the Object URL and removing the temporary anchor
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export function showModal(info: IDefaultSnackbarResponse) {
  modalInfo.value = {
    active: true,
    color: "primary",
    text: info.snackbar.text,
    timeout: info.snackbar.timeout,
  };
}

export async function showModalChooseOption<T>(info: {
  active: boolean;
  text: string;
  timeout: number;
  style: "checkbox" | "button";
  values: { id: T; value: string }[];
}): Promise<T> {
  const promise = await new Promise((_resolve, _reject) => {
    modalInfoChooseOption.value = {
      active: info.active,
      accept: _resolve,
      reject: _reject,
      style: info.style,
      text: info.text,
      timeout: info.timeout,
      values: info.values,
    };
  });
  modalInfoChooseOption.value.active = false;
  return promise as T;
}
