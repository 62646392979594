<template>
    <h3 class="title is-3">Invite Crew</h3>
    <IsLoadingIcon v-if="isAvailableCrewLoading" />
    <template v-else>
        <div class="field">
            <label for="filterStateCheckbox" class="checkbox">
                <input type="checkbox" id="filterStateCheckbox" v-model="filterState">
                Filter State
            </label>
        </div>
        <div class="field">
            <div class="control has-icons-left">
                <input class="input" type="text" v-model="filterName" placeholder="Filter by Name">
                <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                </span>
            </div>
        </div>

        <template v-if="filteredList && filteredList.length === 0">
            <div
                style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: auto; margin-bottom: auto;">
                <span
                    style="display: inline-block; width: 100%; text-align: center; font-weight: 500; margin-top: 2em; margin-bottom: 2em;">No
                    workers matching the search.</span>
                <ButtonIconText v-if="filterState" text="Remove Filters" icon="fas fa-filter"
                    @clicked="filterState = false" />
            </div>
        </template>

        <table v-else class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th style="cursor: pointer;" v-if="!filterState" @click="sortTable('state')">State</th>
                    <th style="cursor: pointer;" @click="sortTable('fullName')">Name</th>
                    <th @click="sortTable('selectedOption')" style="cursor: pointer; text-align:center;">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="person in filteredList" :key="person.id">
                    <td v-if="!filterState">{{ person.state }}</td>
                    <td>{{ person.fullName }}</td>
                    <td style="text-align:center;">
                        <DropDown :selectedId="person.selectedOption" size="is-small" style="font-size: .8em;" :list="[
                            { id: statusCrew.NotDefined, icon: 'fas fa-user-times', iconColor: 'lightgray', text: 'Not Invited' },
                            { id: statusCrew.Pending, icon: 'fas fa-user-clock', iconColor: 'goldenrod', text: 'Invited' },
                            { id: statusCrew.Confirmed, icon: 'fas fa-user-check', iconColor: 'green', text: 'Accepted' },
                            { id: statusCrew.Denied, icon: 'fas fa-user-times', text: 'Denied' }
                        ]" @changedOption="changedOption(person, $event)" />
                    </td>
                </tr>
            </tbody>
        </table>

    </template>
</template>

<script lang="ts" setup>
import { IJobComplete, requiredAndCrew, statusCrew } from '@/types/jobs';
import { Ref, ref, computed } from 'vue';
import { availableCrew, states, isAvailableCrewLoading, saveJobUserList } from '../../data/state';
import { IAvailableCrewSelect } from '@/types/user';
import DropDown from '../elements/DropDown.vue';
import { showModal } from '@/generalFunctions';
import ButtonIconText from '../elements/ButtonIconText.vue';
import IsLoadingIcon from '../elements/IsLoadingIcon.vue';
const filterState = ref(true)
const props = defineProps<{ job?: IJobComplete; reqCrew: requiredAndCrew }>();

const filterName = ref('')

const currentSortField: Ref<keyof IAvailableCrewSelect> = ref('fullName')
const isAscending = ref(true)
function sortTable(field: keyof IAvailableCrewSelect) {
    if (currentSortField.value === field) {
        isAscending.value = !isAscending.value;
    } else {
        currentSortField.value = field;
        isAscending.value = true;
    }
}
const privateAvailableCrew = computed(() => {
    const array = availableCrew.value?.map(el => {
        const foundUser = props.reqCrew.userList.find(el2 => el2.id === el.id)
        if (foundUser) return { ...el, selectedOption: (foundUser.status) } as IAvailableCrewSelect
        return { ...el, selectedOption: (0) } as IAvailableCrewSelect
    })//.filter(el => el.fullName.toLocaleLowerCase().includes('caio souza')); //remove comment if you want just one user to test
    if (!array) return
    array!.sort((a, b) => {
        if (a[currentSortField.value] < b[currentSortField.value]) return isAscending.value ? -1 : 1;
        if (a[currentSortField.value] > b[currentSortField.value]) return isAscending.value ? 1 : -1;
        return 0;
    });
    return array
})

async function changedOption(person: IAvailableCrewSelect, newValue: number) {
    console.log('Calling:saveJobUserList', person, newValue)
    person.selectedOption = newValue;
    const response = await saveJobUserList(props.reqCrew.id, person)
    if (response.error) {
        showModal(response)
        return;
    }
    if (response.UserhasDoubleShift) {
        showModal({ error: true, snackbar: { active: true, color: 'primary', timeout: 10000, text: `${person.fullName} is in more then one shift in a period of 20 hours, please check again!` } })
    }
    if (response.isOverfilled) {
        showModal({ error: true, snackbar: { active: true, color: 'primary', timeout: 10000, text: 'This job have more confirmed crew then the amount needed, please check again!' } })
    }
    const foundElIndex = props.reqCrew.userList.findIndex(el => el.id === person.id)
    if (foundElIndex >= 0) {
        if (person.selectedOption === statusCrew.NotDefined) {
            props.reqCrew.userList.splice(foundElIndex, 1);
        } else {
            props.reqCrew.userList[foundElIndex].status = person.selectedOption
        }

    } else {
        props.reqCrew.userList.push({
            fullName: person.fullName,
            id: person.id,
            status: person.selectedOption
        })
    }

}

const filteredList = computed(() => {
    if (filterName.value !== '' && filterState.value) {
        const jobState = states.value.find(el2 => el2.id === Number(props.job?.state))?.name
        return privateAvailableCrew.value?.filter(el => (el.state === jobState) && el.fullName.toLowerCase().includes(filterName.value.toLowerCase())) || []
    }
    if (filterState.value) {
        const jobState = states.value.find(el2 => el2.id === Number(props.job?.state))?.name
        return privateAvailableCrew.value?.filter(el => el.state === jobState) || []
    }
    if (filterName.value !== '') {
        return privateAvailableCrew.value?.filter(el => el.fullName.toLowerCase().includes(filterName.value.toLowerCase())) || []
    }
    return privateAvailableCrew.value
})
//Leaving disabled for now, always when we used the mass message with all users selected was a mistake
// function toggleAll() {
//
// }



</script>
<style lang="scss" scoped></style>
