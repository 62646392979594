<template>
    <header>
        <div class="icon" @click="setDates(-1)">
            <i class="fas fa-arrow-left"></i>
        </div>
        <span class="title is-3 date-name" @click="isDatePickerOpen = true">
            {{ date.toLocaleString('default', { month: 'long', year: "numeric" }) }}
        </span>
        <span class="icon" @click="setDates(1)">
            <i class="fas fa-arrow-right"></i>
        </span>
    </header>
    <div class="field is-grouped" style="width: 100%">
        <div class="control has-icons-left is-expanded" style="align-self: stretch; flex-grow: 1;">
            <input :class="{ 'is-focused': searchString.length > 0 }" ref="searchInput" class="input is-rounded"
                type="text" v-model="searchString" placeholder="Search..." />
            <Icon icon="fas fa-search" class="is-left" />
        </div>
        <ButtonIconText icon="fas fa-plus" text="Add Job" class="is-primary mr-2" icon-color="white"
            style="align-self: flex-end;" @clicked="addJob" />
        <ButtonIconText :text="isFiltered ? 'Filtered' : 'All'" class="is-rounded"
            :class="{ 'button-active': isFiltered }" icon="fas fa-filter" @clicked="isDatePickerOpen = true">
        </ButtonIconText>
    </div>
    <div class="calendar" :style="{ 'grid-template-rows': `50px repeat(${viewDays.length / 7}, 1fr)` }">
        <span v-for="i in 7" class="day-header">{{ dayNames[viewDays[i - 1].getUTCDay()].substring(0, 3) }}.</span>
        <div class="day" v-for="day in viewDays" :key="day.valueOf()"
            :class="{ 'is-other-month': day.getMonth() != date.getMonth(), 'today': day.getDate() == new Date().getDate() && day.getMonth() == new Date().getMonth() && day.getFullYear() == new Date().getFullYear() }">
            <div style="display: flex;">
                <span class="day-badge">{{ day.getUTCDate() }}</span>
                <span class="day-text">{{ dayOfWeek(day) }}</span>
            </div>
            <span v-for="job in getJobsInDay(day)" :key="job.id"
                :style="{ 'border-color': job.color, 'background-color': job.color.replace('1)', '.07)') }" class="job"
                :class="{ 'is-selected': selection.some(el => el.id == job.id) }" @click.exact="selectJob(job)"
                @click.shift.exact="multiselectjob(job)"
                :title="(isMissingCrew(job) ? 'MISSING CREW\n\n' : '') + job.title + '\n' + formatDateTime('hh:mm xx', job.date) + (isAdmin ? '\n\n(Press [Ctrl] to select multiple jobs.)' : '')">
                <div style="font-size: 0.8em;">{{ formatDateTime('hh:mm xx', job.date) }}</div>
                <span class="fas fa-exclamation" v-if="isMissingCrew(job)"
                    style="color: red; font-weight: 600; margin-right: 2px;"></span>
                <span class="job-title">{{ job.title }}</span>
            </span>
        </div>
    </div>
    <Transition>
        <PopOver @update:is-open="idJob = 0" v-if="idJob && idJob > 0 && isAdmin == false && selection.length <= 1">
            <JobDetail :id="idJob" v-model:selectedTab="formSelectedTabId"></JobDetail>
        </PopOver>
    </Transition>
    <Transition>
        <PopOver :mini="pdfData.length === 0" v-model:is-open="isMultiSelect" v-if="isMultiSelect && isAdmin">
            <p class="title is-5">Generate Timesheet</p>
            <table v-if="pdfData.length == 0" class="table">
                <tbody>
                    <tr v-for="(obj, i) in selection" :key="i">
                        <td style="border-left: 2px solid transparent; padding-left: 2px; padding-right: 2px;"
                            :style="{ 'border-left-color': obj.color, 'background-color': obj.color.replace('1)', '.1)') }">
                            {{ obj.prepend }}
                        </td>
                        <!-- <td>{{ obj.title }}</td> -->
                        <td align="center" style="padding-left: 2px; padding-right: 2px;">
                            <Icon icon="fal fa-trash" @onclick="multiselectjob(obj)"></Icon>
                        </td>
                    </tr>
                </tbody>
            </table>
            <iframe v-if="pdfData.length > 0" :src="'data:application/pdf;base64,' + pdfData"
                style="width: 100%; height: 100%;"></iframe>
            <ButtonIconText class="is-fullwidth is-primary" :class="{ 'is-loading': isPdfLoading }"
                style="margin-top: auto;" icon="fas fa-file-pdf" text="Generate" @clicked="generateTimeSheet" />
        </PopOver>
    </Transition>
    <Transition>
        <PopOver @update:is-open="idJob = 0" v-if="idJob && isAdmin && selection.length <= 1">
            <JobForm :job-id="idJob" v-model:selectedTab="formSelectedTabId" v-model:formSubView="formSubView"
                v-if="isAdmin" />
            <!-- <JobForm :jobId="idJob" v-model:selectedTab="formSelectedTabId" v-model:subView="formSubView" /> -->
        </PopOver>
    </Transition>
    <Transition>
        <PopOver style="display: flex;" v-if="isDatePickerOpen" v-model:is-open="isDatePickerOpen">
            <h3 class="title is-3">Filters</h3>
            <fieldset style="display: flex; flex-direction: column; flex-grow: 1;">
                <div class="field">
                    <label class="label">Matching Text</label>
                    <div class="control">
                        <input class="input" type="text" v-model="searchString" />
                    </div>
                </div>
                <div class="field">
                    <label class="label">Company</label>
                    <div class="control">
                        <div class="select" :class="{ 'is-success': selectedPrepend != '' }">
                            <select v-model="selectedPrepend">
                                <option value="">All</option>
                                <option v-for="(pre, i) in prepends" :value="pre.text" :key="i">{{ pre.fullName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">State</label>
                    <div class="control">
                        <div class="select" :class="{ 'is-success': selectedState != '' }">
                            <select v-model="selectedState">
                                <option value="">All</option>
                                <option v-for="(state, i) in states" :value="state.id" :key="i">{{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Status</label>
                    <div class="control">
                        <div class="select" :class="{ 'is-success': statusSelected != null }">
                            <select v-model="statusSelected">
                                <option :value="null">All</option>
                                <option value="default">Pending & Confirmed</option>
                                <option :value="jobStatus.Finished">Finished</option>
                                <option :value="jobStatus.Cancelled">Cancelled</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Month</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="monthSelected">
                                <option v-for="i in 12" :value="i" :key="i">{{ new Date(new
            Date().setMonth(i - 1)).toLocaleString('default', { month: 'long' }) }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Year</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="yearSelected">
                                <option v-for="i in 6" :value="i" :key="i">{{ new Date().getFullYear() + i - 3 }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field" style="flex-basis: 100%;">
                    <div class="control">
                        <ButtonIconText class="is-fullwidth" icon="fas fa-calendar-day"
                            :text="`This Month (${new Date(new Date().setDate(1)).toLocaleDateString('default', { month: 'short', year: 'numeric' })})`"
                            @clicked="isDatePickerOpen = false" @click="date = new Date(new Date().setDate(1))" />
                    </div>
                </div>
                <ButtonIconText style="margin-top: auto;" class="is-fullwidth is-primary"
                    icon="fas fa-arrow-alt-to-left" text="Close" @clicked="isDatePickerOpen = false" />
            </fieldset>
        </PopOver>
    </Transition>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from 'vue-router';
import JobDetail from "../components/JobDetail.vue";
import JobForm from "@/components/JobForm.vue";
import PopOver from "../components/elements/PopOver.vue";
import { loadPublicJobs, loadAllJobsAsync, prepends, states, doRequest, createNewJob } from "../data/state";
import { IJobComplete, IjobDetails, jobStatus } from "../types/jobs";
// import { relativeNavigationTo } from "./_index";
import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import { formatDateTime, showModal } from "../generalFunctions";
import * as _routerAdmin from "@/pages/_indexAdmin"
import * as _routerCrew from "@/pages/_index"
const routerAdmin = _routerAdmin.default;
const routerCrew = _routerCrew.default;
let isDatePickerOpen = ref(false);
let isAdmin = ref(false);
import Icon from "@/components/elements/Icon.vue";
import Tooltip from "@/components/elements/Tooltip.vue";

let searchInput = ref<HTMLInputElement | null>(null);

let searchString = ref<string>("");

const statusSelected = ref<jobStatus | string | null>('default');

const isFiltered = computed(() => {
    return selectedPrepend.value != "" || selectedState.value != "" || statusSelected.value != null;
});

const selection = ref<IJobComplete[]>([]);
const isMultiSelect = computed({
    get() {
        return selection.value.length > 1;
    },
    set(value: boolean) {
        if (!value) {
            selection.value = [];
        }
    }
});

watch(isMultiSelect, () => {
    pdfData.value = "";
})

async function addJob() {
    const response = await createNewJob();
        if (response.error){
            return;
        } 
    await refreshCalendar()
    const found = _sourceJobs.value.find(el=>el.id==response.jobId)
    if (found){
        selectJob(found)
    }
}

function multiselectjob(job: IJobComplete) {
    if (isAdmin.value) {
        if (selection.value.some(el => el.id == job.id)) {
            selection.value = selection.value.filter(item => item.id != job.id);
        } else {
            selection.value.push(job);
            if (selection.value.length == 1) {
                idJob.value = job.id;
            }
        }
    }
}

function selectJob(job: IJobComplete) {
    if (idJob.value == job.id) {
        idJob.value = 0;
        selection.value = [];
    } else {
        idJob.value = job.id;
        selection.value = [job];
    }
}

let yearSelected = computed({
    get() {
        return date.value.getFullYear() - new Date().getFullYear() + 3;
    },
    set(value) {
        const year = new Date().getFullYear() + value - 3;
        date.value = new Date(date.value.setFullYear(year));
    }
});

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


let monthSelected = computed({
    get() {
        return date.value.getMonth() + 1;
    },
    set(value) {
        date.value = new Date(date.value.setMonth(value - 1));
    }
});

function isMissingCrew(job: IJobComplete): boolean {
    let amount = job.reqCrew.reduce((t, c) => t + c.amount, 0);
    let actual = job.reqCrew.reduce((t, c) => t + c.userList.filter(u => u.status == 2).length, 0);
    return amount != actual;
}

const selectedPrepend = ref<string>("");
const selectedState = ref<string>("");
const _sourceJobs = ref<IJobComplete[]>([]);

const idJob = defineModel('idJob', { type: Number });
const formSelectedTabId = defineModel('formSelectedTabId', { type: String });
const formSubView = defineModel('formSubView', { type: String });

watch(formSelectedTabId, navigateJobForm);
watch(idJob, navigateJobForm);
watch(formSubView, navigateJobForm);
function navigateJobForm() {
    if (idJob.value == undefined || idJob.value == 0) {
        selection.value = [];
    } else {
        if (!selection.value.some(el => el.id == idJob.value)) {
            selection.value.push(jobs.value.find(j => j.id == idJob.value) as IJobComplete);
        }
    }

    if (isAdmin.value) {
        if (idJob.value && idJob.value > 0) {
            const params = { name: 'calendar', params: { idJob: idJob.value, formSelectedTabId: formSelectedTabId.value, formSubView: formSubView.value } };
            routerAdmin.push(params);
        } else {
            const params = { name: 'calendar', params: { idJob: undefined, formSelectedTabId: undefined, formSubView: undefined } };
            routerAdmin.push(params);
        }
        refreshCalendar();//TODO: UPDATE CALENDAR WHEN CLOSE JOB FORM
        return
    }
    if (idJob.value && idJob.value > 0) {
        const params = { name: 'calendar', params: { idJob: idJob.value, formSelectedTabId: formSelectedTabId.value } };
        routerCrew.push(params);
    } else {
        const params = { name: 'calendar', params: { idJob: undefined, formSelectedTabId: undefined } };
        routerCrew.push(params);
    }
    return
}

const pdfData = ref<string>("");
const isPdfLoading = ref(false);
async function generateTimeSheet() {
    isPdfLoading.value = true;
    const data = await doRequest<{ duplicateTime: [], error: boolean, pdfData: string }>("/api/time/generateTimesheetJob", "POST", { idJob: selection.value.map(item => item.id), invoiceMarkJobAsFinished: false });
    if (!data.error) {
        pdfData.value = data.pdfData;
    }
    isPdfLoading.value = false;
}

let jobs = computed(() => {

    let jobs: Array<IJobComplete> = _sourceJobs.value;
    if (selectedPrepend.value != null && selectedPrepend.value != "") {
        jobs = jobs.filter(item => item.prepend == selectedPrepend.value);
    }
    if (selectedState.value != null && selectedState.value != "") {
        jobs = jobs.filter(item => item.state == selectedState.value);
    }
    if (searchString.value != null && searchString.value != "") {
        jobs = jobs.filter(item => JSON.stringify(item).toLowerCase().indexOf(searchString.value.toLowerCase()) > -1);
    }

    if (statusSelected.value == 'default') {
        jobs = jobs.filter(item => item.status == jobStatus.Pending || item.status == jobStatus.Confirmed);
    } else if (statusSelected.value != null) {
        jobs = jobs.filter(item => item.status == statusSelected.value);
    }

    return jobs;
});

function getMonday(date: Date) {
    const tempDate = new Date(date);
    tempDate.setUTCDate(tempDate.getUTCDate() - (tempDate.getUTCDay() + 6) % 7); // Adjust to Monday
    return tempDate;
}

const date = ref(new Date(new Date().setDate(1)));
// const debugDate = new Date(new Date().setMonth(9))
// debugDate.setDate(1)
// const date = ref(debugDate);
const viewStartDate = computed(() => {
    const tempDate = new Date(date.value.valueOf());
    //TODO: should be some kind of local handing here
    //tempDate.setDate(1 - tempDate.getDay() +1);
    return getMonday(tempDate)
    // tempDate.setUTCDate(1 - tempDate.getUTCDay());
    // return tempDate;
});

const viewEndDate = computed(() => {
    const tempDate = new Date(date.value.valueOf());
    tempDate.setMonth(tempDate.getMonth() + 1);
    let offset = tempDate.getDay() % 7;
    //TODO: should be some kind of local handing here
    //    tempDate.setDate(tempDate.getDate() + (6 - offset));
    tempDate.setDate(tempDate.getDate() + (7 - offset));
    return tempDate;
});

function focusSearch(args: KeyboardEvent) {
    if (args.key == 'f' && args.ctrlKey) {
        searchInput.value?.focus();
        args.preventDefault();
    }
    if (args.key == 'Escape') {
        searchInput.value?.blur();
        args.preventDefault();
    }
}

onUnmounted(() => {
    removeEventListener('keydown', focusSearch, false);
})

onMounted(async () => {
    addEventListener('keydown', focusSearch, false);

    const parts = routerAdmin.currentRoute.value.path.split("/");
    if (parts.length >= 2 && parts[1] == 'admin') {
        isAdmin.value = true;
    }
    await refreshCalendar();
    //check if is on mobile view
    if (window.innerWidth < 1024) {
        //scroll to the element with class today
        const today = document.querySelector('.today');
        if (today) {
            today.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }
});

watch([viewStartDate, viewEndDate], () => {
    refreshCalendar();
}, { deep: true })

async function refreshCalendar() {
    if (isAdmin.value == true) {
        let temp = await loadAllJobsAsync(viewStartDate.value.valueOf(), viewEndDate.value.valueOf() + 24 * 60 * 60 * 1000);//plus one day to be sure it will be displayed on calendar
        _sourceJobs.value = temp.jobs;
    } else {
        _sourceJobs.value = await loadPublicJobs(viewStartDate.value, viewEndDate.value);
    }
}

type Increment = -1 | 1;

function setDates(i: Increment) {
    const d = new Date(date.value.setMonth(date.value.getMonth() + i));
    date.value = d;
    // refreshCalendar();
}

function getJobsInDay(_date: Date): Array<IJobComplete> {
    const date = new Date(_date.valueOf());
    date.setUTCHours(0, 0, 0, 0);
    // const nextDate = new Date(new Date(date.valueOf()).setDate(date.getDate() + 1));

    if (jobs.value && jobs.value.length > 0) {
        const results = jobs.value?.filter(item => {
            const itemDate = (new Date(item.date));
            // if (item.id === 6377) {
            //     if (date.getDate() === 17) {
            //         console.log(_date.getDate(), _date.getMonth(), date.getDate(), date.getMonth(), itemDate.getUTCDate(), itemDate.getUTCMonth());
            //         console.log('item', item)
            //         console.log((date.getDate() === itemDate.getUTCDate() && date.getMonth() === itemDate.getUTCMonth()))
            //     }
            // }
            if (date.getDate() === itemDate.getUTCDate() && date.getMonth() === itemDate.getUTCMonth())
            console.log((date.getDate() === itemDate.getUTCDate() && date.getMonth() === itemDate.getUTCMonth()),date.getDate(), itemDate.getUTCDate(), date.getMonth(), itemDate.getUTCMonth())
            return (date.getDate() === itemDate.getUTCDate() && date.getMonth() === itemDate.getUTCMonth());
            // return item.date >= date.valueOf() && item.date <= nextDate.valueOf();
        });
        if (results === undefined) {
            return [];
        } else {
            return results;
        }
    } else {
        return [];
    }
}

const viewDays = computed(() => {
    let d = new Date(viewStartDate.value.valueOf());
    d.setUTCHours(0, 0, 0, 0);
    let array: Array<Date> = [];
    while (d.valueOf() <= viewEndDate.value.valueOf()) {
        array.push(new Date(d.valueOf()));
        d = new Date(d.setUTCDate(d.getUTCDate() + 1));
    }
    return array;
});

function dayOfWeek(date: Date): string {
    switch (date.getDay()) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
    return "";
}

</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";
// @import "../../node_modules/bulma/sass/utilities/_all.sass";

.button-active {
    border-color: $primary;
    background-color: $primary-light;
}

.day-header {
    display: inline-block;
    height: 50px;
    font-weight: 500;
    text-align: center;
    padding: 0.5em;
    border-bottom: 1px solid $grey-lightest;

    @include until(1023px) {
        display: none;
    }
}

.calendar {
    height: calc(100vh - 10em);
    display: grid;
    width: calc(100% - 3em);
    grid-template-columns: repeat(7, 1fr);

    .day-text {
        display: none;
        margin-left: 2px;
        font-weight: 500;
    }

    @include until(1023px) {
        display: block;
    }

    >.day {
        &.today {
            .day-badge {
                background-color: $primary;
                color: white;
            }
        }

        &.is-other-month {
            background-color: $grey-lightest;

            @include until(1023px) {
                display: none;
            }
        }

        border: 1px solid $grey-lightest;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        min-height: 250px;

        .day-text {
            @include until(1023px) {
                display: inline-block;
            }
        }

        @include until(1023px) {
            min-height: 0;
            margin-top: 2em;
            border: none;
        }

        .day-badge {
            border-radius: 100%;
            background-color: $grey-lighter;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include until(1023px) {
                background-color: black;
                color: white;
            }

        }

        .job {
            user-select: none;
            overflow: hidden;
            display: block;
            text-wrap: nowrap;
            cursor: pointer;
            margin-top: 0.5em;
            font-weight: 100;
            font-size: .8em;
            transition: border-width 0.2s ease-in-out;

            .job-title {
                color: black;
                font-weight: 300;
            }

            &.is-selected {
                border-left-width: 10px;
            }

            &:not(.is-selected) {
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
            }

            border-style: solid;
            border-left-style: solid;

            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 3px;

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            padding-left: 3px;

            @include until(1023px) {
                font-size: 1.2em;
            }

        }
    }
}



header {
    .date-name {
        font-size: 1.5em;
        font-weight: 100;
        cursor: pointer;
        border-radius: 5px;
    }

    .date-name:hover {
        background-color: $primary-light;
    }

    >span.title.is-3 {
        margin-bottom: 0;
    }

    .icon {
        margin: 1em;
        cursor: pointer;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

:deep(.icon-text) {
    flex-wrap: nowrap;
}

.selector {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    >*:nth-child(6)::after {
        content: '...';
        font-size: 1.5em;
        margin-top: 0.5em;

    }
}

.select.is-success {
    select {
        background-color: $success-light;
    }
}

fieldset>div.field .select,
fieldset>div.field .select select {
    width: 100%;
}
</style>